<template>
  <div class="return91">
    <loader-wrapper v-if="isLoading" />
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn" @click="$router.go(-1)">
          <BackIcon />
        </span>
        <h1>Return91</h1>
      </div>
    </header>
    <section class="return-section">
      <div class="return-card">
        <h2>{{ $t("_returnOrders") }}</h2>
        <div class="status-blk">
          <div
            class="status-col"
            @click="goToPage('/order-to-be-picked-from-customer')"
          >
            <span class="state-val">
              <img src="@/assets/images/tobe-picked.svg" alt="" />
              <span class="circle">
                <strong id="item_count4">
                  {{ orders ? orders.length : 0 }}
                </strong>
              </span>
            </span>
            <p>{{ $t("_tobePicked") }}</p>
          </div>
          <div class="status-col" @click="goToPage('/return-orders')">
            <span class="state-val">
              <img src="@/assets/images/under-proceesign.svg" alt="" />
              <span class="circle">
                <strong id="item_count5">
                  {{
                    dashboardData.to_be_return ? dashboardData.to_be_return : 0
                  }}
                </strong>
              </span>
            </span>
            <p>{{ $t("_tobeReturned") }}</p>
          </div>
          <div class="status-col" @click="goToPage('/return-orders-tracking')">
            <span class="state-val">
              <img src="@/assets/images/ready-return.svg" alt="" />
              <span class="circle">
                <strong>
                  {{ dashboardData ? dashboardData.no_of_lots : 0 }}
                </strong>
              </span>
            </span>
            <p>{{ $t("_ordersTracking") }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BackIcon from "@/components/svgIcons/BackIcon";
// import ApiRequest from '../services/ApiRequest';
import { BASE_API } from "../utils/constants";
// import { GET } from '../utils/HttpStatusCodeConst';
import { SessionHelper, X_AUTH_ID } from "../services/Session";
import Axios from "axios";
import LoaderWrapper from "../components/commonUI/LoaderWrapper.vue";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      dashboardData: [],
      offset: 0,
      orders: [],
      isLoading: false,
    };
  },
  components: {
    BackIcon,
    LoaderWrapper,
  },
  methods: {
    goToPage(_path) {
      if (_path === "/delivered-orders" || _path === "/missing-lost-orders") {
        _path = `${_path}?partnerId=${this.$route.query.partnerId}`;
      }
      this.$router.push(_path);
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      const { data } = await Axios.get(BASE_API + "/partner91/orders/count/", {
        headers: {
          "x-auth-id": SessionHelper.getCookie(X_AUTH_ID),
        },
      });
      this.dashboardData = data[0].return;

      const { data: to_be_picked } = await Axios.get(
        `https://groupbuybe.payboard.in/orderService/pt91/get-order-for-partner91?limit=100&offset=0`,
        {
          headers: {
            "x-auth-id": SessionHelper.getCookie(X_AUTH_ID),
          },
        }
      );
      this.orders = to_be_picked.orderDTOs;
      this.isLoading = false;
    } catch (e) {
      Swal.fire({ text: "Something went wrong", icon: "error" });
      this.isLoading = false;
    }
    // console.log(this.dashboardData);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/return91.scss";
</style>
